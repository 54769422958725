<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.action-modal {
  z-index: 10;
  position: absolute;
  margin-top: -10px;
  background: white;
}
ul {
  list-style-type: none;
}
.modal-button:hover {
  background-color: rgb(214 208 201) !important;
}
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.action-list:hover {
  background-color: #d7d9f8;
  transition: 0.7s;
  color: black;
  border-radius: 5%;
}
</style>
<template>
  <div class="card card-custom">
    <div class="full-load" v-if="generateReport">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="card-body">
      <div class="row" v-if="AppType">
        <div class="col-md-4">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Search by enrollment no or tcn or name or appointment no"
              aria-describedby="basic-addon2"
              v-model="search_text"
              @keypress.enter="search()"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-info"
                type="button"
                @click="search()"
              >
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <enrollmentDetailModal
        :key="key"
        v-if="enrollmentDetailModalShow"
        v-on:closemodal="closeEnrollmentModal"
        :loading-data="loadingData"
        :has-data="hasData"
        :is_archival="true"
        :display-logs="displayLogs"
        v-on:refreshtable="refreshTable"
        :show-modal="enrollmentDetailModalShow"
        :enrollment-data="enrollmentData"
        :index-num="currentIndex"
        @reLoadTable="reTableReload"
      ></enrollmentDetailModal>
      <appointmentDetailModal
        :key="key"
        v-if="appointmentDetailModalShow"
        v-on:closemodal="closeModal"
        v-on:updatepaymentstatus="updatepaymentstatus"
        :loading-data="loadingData"
        :has-data="hasData"
        :show-modal="appointmentDetailModalShow"
        :appointment-data="appointmentData"
        :index-num="currentIndex"
        :show-qr-code="showQrCode"
        :is_archival="true"
        :token="token"
        @reLoadTable="reTableReload"
      ></appointmentDetailModal>
      <assignarchival
        :key="key"
        v-if="AssignModalShow"
        v-on:closemodal="closeAssignModal"
        :loading-data="loadingData"
        :show-modal="AssignModalShow"
        :archivalData="archivalData"
        :index-num="currentIndex"
      >
      </assignarchival>

      <div class="row" v-if="AppType">
        <canvas
          id="barcode"
          class="d-none"
          style="width: inherit; height: 95px"
        ></canvas>

        <div class="col-md-2 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="serviceOutlet"
              @change="loadServices()"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Location</option>
              <option
                v-for="(citem, index) in getClients"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-2 pr-0">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="service"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Service</option>
              <option
                v-for="(citem, index) in getServices"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-2 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="company"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Company</option>
              <option
                v-for="(citem, index) in filteredCompanies"
                :key="index"
                :value="citem.id"
              >
                {{ citem.company_name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-1 pl-2 pr-5 mr-10"  :style="company=='all'?'display:block':'display:none'">
          <div class="form-group" >
            <input
            :disabled="company!='all'??false"
              style="width: 150%"
              @keypress.enter="appData()"
              type="date"
              v-model="date_from"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-1 pl-2 pr-5 mr-10 ml-2" :style="company=='all'?'display:block':'display:none'">
          <div class="form-group">
            <input
            :disabled="company!='all'??false"
              style="width: 150%"
              @keypress.enter="appData()"
              type="date"
              v-model="date_to"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-1 pl-2 pr-0 ml-2">
          <div class="form-group">
            <select
              @keypress.enter="appData()"
              v-model="archival_period"
              name=""
              class="form-control"
              id=""
            >
              <option value="all">All Archival</option>
              <option
                v-for="(citem, index) in getArchivals"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
          <button class="btn btn-outline-info" @click="advSearch()">
            <i class="fa fa-search"></i>
          </button>
          <download-excel
            v-if="appoinments.length > 0"
            class="btn btn-primary ml-2"
            :fetch="fetchData"
            :header="header"
            :worksheet="todays_date"
            name="archive-report.xls"
            :fields="json_fields"
            :before-generate="startDownload"
            :before-finish="finishDownload"
          >
            <i class="test-white fa fa-file-excel"></i>
          </download-excel>
        </div>
      </div>

      <hr v-if="appoinments.length > 0 && !getLoadingData && AppType" />
      <div
        class="row"
        v-if="appoinments.length > 0 && !getLoadingData"
        style="position: relative"
      >
        <div class="col-md-12">
          <v-table
            :columns="columns"
            v-if="AppType && DashType == 'appointment_list'"
          >
            <tr v-for="(item, index) in appoinments" :key="index">
              <td>
                <h6
                  style="font-size: 13px; font-weight: 400"
                  v-if="
                    item.archival_start_date != null &&
                      item.archival_start_date != ''
                  "
                >
                  {{ dateFormat(item.archival_start_date) }}
                </h6>
                <!-- <h6
                  style="font-size: 13px; font-weight: 400"
                  v-if="item.enroll_date != null && item.enroll_date != ''"
                >
                  {{ item.enroll_date | dateFormat }}
                </h6> -->
                <!-- <h6
                  style="font-size: 13px; font-weight: 400"
                  v-else-if="
                    item.schedule_date != null && item.schedule_date != ''
                  "
                >
                  {{ item.schedule_date | dateFormat }}
                </h6> -->
                <h6 style="font-size: 13px; font-weight: 400" v-else></h6>
              </td>
              <td>
                <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.appointment_id }}</a
                >
              </td>
              <td>
                <a
                  href="javascript:void;"
                  @click="openEnrollmentDetail(item.enrollment_id, index)"
                >
                  {{ item.enrollment_id }}</a
                >
              </td>
              <td>
                <!-- <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.applicant }}</a
                > -->
                {{ item.applicant }}
              </td>
              <td>
                <!-- <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, true)
                  "
                >
                  {{ item.applicant }}</a
                > -->
                {{ item.tcn }}
              </td>
              <td>
                {{ item.company_name }}
              </td>

              <td>
                <b>
                  {{ item.service }}
                  <span v-if="item.is_additional"
                    >,{{ item.is_additional }}</span
                  >
                </b>
                <br />
                {{ item.originating_location_name }}
              </td>

              <td>
                {{ item.archival_name }}
              </td>
              <td
                v-if="
                  item.archival_expiry_date != null &&
                    item.archival_expiry_date != ''
                "
              >
                {{ dateFormat(item.archival_expiry_date) }}
              </td>
              <td
                v-else-if="
                  item.expiration_date != null && item.expiration_date != ''
                "
              >
                {{ dateFormat(item.expiration_date) }}
              </td>
              <td v-else></td>
              <td>
                <!-- <select> -->
                <button
                  class="text-center modal-button modal-box"
                  @click="IndexClick(index)"
                  v-if="item.enrollment_id != ''"
                  style="background-color: rgb(243, 241, 239); padding:0.5rem 0.5rem 0.4rem 0.4rem;"
                >
                  <i class="las la-ellipsis-v modal-box"></i>
                </button>
                <div
                  class="action-modal"
                  v-show="showActionButton === index"
                  style="
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 5%;
                    font-size:0.8rem;
                  "
                >
                  <ul
                    style="
                      border-radius: 5%;
                      margin: 5px;
                      margin-top: 5px;
                      margin-bottom: 5px;
                    "
                  >
                    <li
                      class="action-list"
                      style="cursor: pointer; padding: 5px"
                      @click="edit(item.enrollment_id, index)"
                    >
                      <button>Retrieve</button>
                    </li>
                    <hr />
                    <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                      @click="
                        assignEnrollmentCompany(item.enrollment_id, index)
                      "
                    >
                      <button>Assign Company</button>
                    </li>
                    <hr />
                    <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                    >
                      <button
                        @click="
                          changeEnrollmentArchive(
                            item.enrollment_id,
                            item.tcn,
                            false
                          )
                        "
                      >
                        Renew Archival
                      </button>
                    </li>

                    <hr
                      v-if="
                        item.enrollment_id != null &&
                          item.enrollment_id != '' &&
                          item.has_invoice
                      "
                    />
                    <li
                      v-if="
                        item.enrollment_id != null &&
                          item.enrollment_id != '' &&
                          item.has_invoice
                      "
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                    >
                      <button @click="openInvoice(item.enrollment_id)">
                        Invoice
                      </button>
                    </li>
                    <hr />
                    <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                    >
                      <button
                        @click="removeArchival(item.enrollment_id, item.tcn)"
                      >
                        Remove Archive
                      </button>
                    </li>
                  </ul>
                </div>
                <!-- <option>Retrieve</option>
                  <option>Assign Company</option>
                  <option></option>
                </select> -->

                <!-- <button
                  v-if="
                    item.enrollment_id &&
                    item.enrollment_id != '' &&
                    (item.service_type == 'fingerprintcard' ||
                      item.service_type == 'fdle' ||
                      item.service_type == 'atf')
                  "
                  class="btn btn-sm btn-info"
                  @click="edit(item.enrollment_id, index)"
                >
                  Retrieve
                </button> -->
              </td>
              <!-- <td class="text-center">
                {{<i
                  class="text-success fa fa-check"
                  v-if="item.payment_status == 'P'"
                ></i>
                <i
                  class="text-danger fa fa-times"
                  v-if="item.payment_status == 'U'"
                ></i>
                <span
                  v-if="item.payment_status == 'Refund'"
                  style="cursor: pointer"
                  class="badge text-white badge-danger"
                >
                  Refunded</span
                >}}
              </td> -->
              <!-- <td class="pr-0 text-right" style="width: 120px">
                <a
                  v-if="
                    item.payment_status == 'P' && item.appt_status != 'Cancel'
                  "
                  href="javascript:void()"
                  @click="openInvoice(item.token)"
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  >Invoice
                  <i class="fa fa-arrow-right ml-1"></i>
                </a>
              </td> -->
            </tr>
          </v-table>

          <v-table :columns="columns" v-else-if="DashType == 'today'">
            <tr v-for="(item, index) in appoinments" :key="index">
              <td class="text-right">
                <a
                  href="javascript:void;"
                  @click="
                    openAppointmentDetail(item.id, item.token, index, false)
                  "
                >
                  {{ item.appointment_id }}</a
                >
              </td>
              <td>
                {{ item.applicant }}
              </td>

              <td>
                <b>
                  {{ item.service }}
                </b>
                <br />
                {{ item.originating_location_name }}
              </td>
            </tr>
          </v-table>

          <v-table :columns="columns" v-else>
            <tr v-for="(item, index) in appoinments" :key="index">
              <td>
                <h6 style="font-size: 12px">
                  {{ item.schedule_date | dateFormat }}
                </h6>
                <h6 style="font-size: 12px">
                  {{ item.start_time | timeFormat(item.schedule_date) }}
                </h6>
              </td>
              <td class="text-right">
                {{ item.id }}
              </td>
              <td>
                {{ item.applicant }}
              </td>

              <td>
                <b>
                  {{ item.service }}
                </b>
                <br />
                {{ item.originating_location_name }}
              </td>
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="appoinments.length < 1 && !getLoadingData">
        <div class="col-md-12 text-center">
          <h4>no archived enrollment(s) found</h4>
        </div>
      </div>

      <div class="row" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
      <hr
        v-if="
          pagination.total_page > 1 && appoinments.length > 1 && !getLoadingData
        "
      />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="
          pagination.total_page > 1 && appoinments.length > 1 && !getLoadingData
        "
        :pagination="pagination"
      ></vl-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  FETCH_CLIENT_ARCHIVAL_APPOINTMENT,
  FETCH_CLIENTS,
  FETCH_SERVICES,
  UPDATE_APPOINTMENT_STATUS,
  FETCH_SELF_APPOINMENT,
  APPOINTMENT_LOGS,
  FETCH_ARCHIVALS,
  FETCH_ENROLLMENT,
  ENROLLMENT_LOGS,
  FETCH_ARCHIVAL_ENROLLMENT,
  FETCH_MASTER_COMPANIES,
  DOWNLOAD_CLIENT_ARCHIVAL_APPOINTMENT
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import vlPagination from "@/includes/table/Pagination";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import enrollmentDetailModal from "@/components/frontend/modal/enrollmentdetail";
import * as PDF417 from "pdf417-generator";
import appointmentDetailModal from "@/components/frontend/modal/appointmentdetail";
import assignarchival from "@/pages/backend/appointment/archival/assignarchivalmodal";
import { REMOVE_ARCHIVE } from "../../../../core/services/store/actions.type";
import JsonExcel from "vue-json-excel";
// import JsonExcel from "vue-json-excel";
export default {
  components: {
    vTable,
    vlPagination,
    vDataLoader,
    enrollmentDetailModal,
    appointmentDetailModal,
    assignarchival,
    downloadExcel: JsonExcel
    // downloadExcel: JsonExcel
  },
  computed: {
    filteredCompanies() {
      let tempMasterCompanies = this.getMasterCompanies;
      tempMasterCompanies = tempMasterCompanies.filter(item => {
        return item.use_in_archive;
      });
      return tempMasterCompanies;
    },
    ...mapGetters([
      "currentUser",
      "getClients",
      "getServices",
      "getArchivals",
      "getMasterCompanies"
    ])
  },
  props: {
    AppType: {
      type: Boolean,
      default: true
    },
    DashType: {
      type: String,
      default: "appointment_list"
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    },
    timeFormat: function(value, date) {
      if (date == null) {
        return;
      }
      var fullDate = date + " " + value;
      var a = moment(fullDate).format("hh:mm a");
      return a;
    },
    dateFormat(date) {
      if (date == null && date == "") {
        return "";
      }
      return moment(date).format("MM/DD/YYYY");
    },
    payementStatus(status) {
      if (status == "C") {
        return "Card";
      }
      if (status == "A") {
        return "Abbreviation";
      }
      if (status == "O") {
        return "On-site";
      }
      if (status == "CP") {
        return "Company-payment";
      }
      if (status == "Cancel") {
        return "Cancel";
      }
    },
    apptStatus(status) {
      var s = "";
      switch (status) {
        case "P":
          s = "Pending";
          break;

        case "C":
          s = "Completed";
          break;

        case "U":
          s = "No-Show";
          break;
        case "Cancel":
          s = "Canceled";
          break;
      }
      return s;
    }
  },
  data() {
    return {
      enrollmentData: {},
      search_text: "",
      todays_date: this.$moment(new Date()).format("MM-DD-YYYY"),
      generateReport: false,
      header: ["Archive Report"],
      enrollmentDetailModalShow: false,
      AssignModalShow: false,
      appointmentDetailModalShow: false,
      enrollmentid: 0,
      json_fields: {
        "Archived Date": {
          field: "archival_start_date",
          callback: value => {
            if (value == null || value == "") {
              return "";
            }
            return moment(value)
              .format("MM/DD/YYYY")
              .toString();
          }
        },
        "Appointment No": "appointment_id",
        "Enrollment No": "enrollment_id",
        Applicant: "applicant",
        Location: "originating_location_name",
        Service: "service",
        TCN: "tcn",
        Company: "company_name",
        Archival: "archival_name",
        "Expires on": {
          field: "archival_expiry_date",
          callback: value => {
            if (value == null || value == "") {
              return "";
            }
            return moment(value)
              .format("MM/DD/YYYY")
              .toString();
          }
        }
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ],
      showQrCode: false,
      getLoadingData: false,
      columns: [
        {
          name: "archival_start_date",
          width: "",
          label: "Archived Date",
          class: ""
        },
        {
          name: "App_no",
          width: "",
          label: "App No.",
          class: ""
        },
        {
          name: "enrollment_id",
          width: "",
          label: "Enr No",
          class: ""
        },
        {
          name: "applicant",
          width: "",
          label: "Applicant",
          class: ""
        },
        {
          name: "tcn",
          width: "",
          label: "TCN",
          class: ""
        },
        {
          name: "company_name",
          width: "",
          label: "Company",
          class: ""
        },
        {
          name: "Service/location",
          width: "",
          label: "Service/Location",
          class: ""
        },

        {
          name: "archival_name",
          width: "",
          label: "Archival",
          class: ""
        },
        {
          name: "Expires on",
          width: "",
          label: "Expires on",
          class: ""
        },
        {
          name: "Action",
          width: "10%",
          label: "Action",
          class: ""
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Archival List",
          route: ""
        }
      ],
      service: "all",
      serviceOutlet: "all",
      company: "all",
      archival_period: "all",
      status: "all",
      payment_status: "all",
      date_from: this.$moment(new Date()).format("YYYY-MM-DD"),
      date_to: this.$moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD"),
      appoinments: [],
      offset: 0,
      limit: 20,
      pagination: {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      },
      selectedAppId: 0,
      appointmentData: {},
      archivalData: {},
      currentIndex: -1,
      loadingData: false,
      hasData: false,
      displayLogs: false,
      msg: "",
      token: "",
      key: 0,
      showActionButton: ""
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_MASTER_COMPANIES);
    if (window.localStorage.getItem("appoitmentsearch") != null) {
      let search = JSON.parse(window.localStorage.getItem("appoitmentsearch"));
      this.service = search.service;
      this.status = search.status;
      this.payment_status = search.payment_status;
      this.serviceOutlet = search.service_outlet
        ? search.service_outlet
        : "all";
      this.date_from = search.date_from
        ? search.date_from
        : this.$moment(new Date()).format("YYYY-MM-DD");
      this.date_to = search.date_to
        ? search.date_to
        : this.$moment(new Date())
            .add(7, "days")
            .format("YYYY-MM-DD");
      this.type = search.currentUser;
      this.$store.dispatch(FETCH_SERVICES, this.serviceOutlet);
      this.$store.dispatch(FETCH_ARCHIVALS, this.serviceOutlet);
    } else {
      this.serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
      this.$store.dispatch(FETCH_SERVICES, this.serviceOutlet);
      this.$store.dispatch(FETCH_ARCHIVALS, this.serviceOutlet);
    }
    this.loadClients();
    if (this.AppType) {
      this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    }
    this.appData();
    document.addEventListener("click", this.handleDocumentClick);
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener("click", this.handleDocumentClick);
  },

  methods: {
    removeArchival(enrollment_id, tcn) {
      let data = {
        enrollment_id: enrollment_id
      };
      let message = `You want to remove this Enrollment# ${enrollment_id} \n
      TCN: ${tcn} from archive `;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: message,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store
              .dispatch(REMOVE_ARCHIVE, data)
              .then(res => {
                if (res.code == 200) {
                  this.$toastr.s(res.msg);
                  this.refreshData();
                }
              })
              .catch(errs => {
                this.errorMsg = errs.error;
                this.$toastr.e(errs.error);
              });
          }
        });
    },

    handleDocumentClick(event) {
      const hasClass = event.target.classList.contains("modal-box");
      if (!hasClass) {
        // Call the Vue method using this
        this.IndexClick(-1);
      }
    },

    refreshTable(index, company_name) {
      this.appoinments[index].company_name = company_name;
    },
    search() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.appData();
    },
    reTableReload(index) {
      this.appoinments[index].payment_status = "Refund";
    },
    advSearch() {
      this.search_text = "";
      this.search();
    },
    openInvoice(enrollment_id) {
      this.$router.push({
        name: "client.enrollment.invoice.details",
        params: {
          slug: this.$route.params.client_slug,
          enrollment_id: enrollment_id
        }
      });
    },
    updatepaymentstatus(i, ps, is_drug_testing) {
      if (is_drug_testing) {
        this.refreshData();
      }
      this.appoinments[i].payment_status = ps;
    },
    closeModal() {
      this.appointmentDetailModalShow = false;
    },
    closeEnrollmentModal() {
      this.enrollmentDetailModalShow = false;
    },
    async loadClients() {
      await this.$store.dispatch(FETCH_CLIENTS);
    },
    closeAssignModal() {
      this.AssignModalShow = false;
      this.appData();
    },
    async fetchData() {
      this.header = ["Archive Report"];
      this.header.push("Date: " + this.date_from + " to " + this.date_to);
      if (this.serviceOutlet != "all") {
        var location_data = this.getClients.filter(
          item => item.id === this.serviceOutlet
        )[0];
        this.header.push("Location: " + location_data.name);
      } else {
        this.header.push("Location: All");
      }
      if (this.service != "all") {
        var service_data = this.getServices.filter(
          item => item.id === this.service
        )[0];
        this.header.push("Service: " + service_data.name);
      } else {
        this.header.push("Service: All");
      }
      if (this.company != "all") {
        var companies = this.filteredCompanies.filter(
          item => item.id === this.company
        )[0];
        this.header.push("Company: " + companies.company_name);
      } else {
        this.header.push("Company: All");
      }
      if (this.archival_period != "all") {
        var archivals = this.getArchivals.filter(
          item => item.id === this.archival_period
        )[0];
        this.header.push("Archive: " + archivals.name);
      } else {
        this.header.push("Archive: All");
      }

      // this.getLoadingData = true;
      //   console.log(this.archival_period);
      var data_ = {
        search_text: this.search_text,
        service: this.service,
        service_outlet: this.serviceOutlet,
        date_from: this.date_from,
        date_to: this.date_to,
        archival_period: this.archival_period,
        type: this.currentUser.u.is_client ? "c" : "sp",
        page: this.pagination.current_page,
        company: this.company
      };

      let res = await this.$store.dispatch(
        DOWNLOAD_CLIENT_ARCHIVAL_APPOINTMENT,
        data_
      );
      return res.data;
      // this.$store
      //   .dispatch(FETCH_CLIENT_ARCHIVAL_APPOINTMENT, data_)
      //   .then(res => {
      //     this.getLoadingData = false;
      //     this.appoinments = res.data;
      //     this.pagination.total_records = res.total_record;
      //     this.pagination.total_page = res.page_range;
      //     this.pagination.received_per_page_data = res.data.length;
      //     this.pagination.per_page_data =
      //       this.DashType == "appointment_list" ? 15 : 8;

      //     // console.log(res);
      //   })
      //   .catch(err => {
      //     this.getLoadingData = false;
      //     this.msg = err.error;
      //   });
    },

    openAppointmentDetail(appId, token, index, showQrCode) {
      let now = moment(new Date());
      let appointment_date = moment(
        this.appoinments[index].schedule_date +
          " " +
          this.appoinments[index].start_time
      );
      if (this.appoinments[index].cancel == 1 && this.currentUser.u.is_client) {
        return 0;
      }

      if (
        (now.diff(appointment_date, "hours") >= 24 &&
          this.currentUser.u.is_client) ||
        (this.currentUser.u.is_client && this.appoinments[index].is_locked == 1)
      ) {
        return 0;
      }
      this.showQrCode = showQrCode;
      this.currentIndex = index;
      this.appointmentDetailModalShow = true;
      this.selectedAppId = appId;
      this.loadingData = true;
      var data = {
        token: token,
        appointment_id: appId
      };

      this.$store
        .dispatch(FETCH_SELF_APPOINMENT, data)
        .then(res => {
          this.appointmentData = res.data;
          this.token = token;

          this.loadingData = false;
          this.hasData = res.hasData;
          this.key += 1;

          if (showQrCode) {
            var DBB = this.$moment(res.data.dob).format("MMDDYYYY");
            var DBC = res.data.gender == "Female" ? 2 : 1;
            var DAU = ((res.data.height * 12) / 100).toFixed(2);
            var value =
              "@\n\nANSI 636020090102DL00410279ZC03200010DLDAQ171774992\nDCA\nDCB\nDCD\nDBA\nDCS" +
              res.data.lname +
              "\nDAC" +
              res.data.fname +
              "\nDAD" +
              res.data.mname +
              "\nDBD\nDBB" +
              DBB +
              "\nDBC" +
              DBC +
              "\nDAY" +
              res.data.eye_color +
              "\nDAU0" +
              DAU * 100 +
              " in\nDAG" +
              "\nDAI" +
              res.data.city +
              "\nDAJ" +
              res.data.address +
              "\nDAK" +
              res.data.zipcode +
              "\nDAQ\nDCF\nDCG\nDDE\nDDF\nDDG";
            var canvas = document.getElementById("barcode");
            PDF417.draw(value, canvas);
            res.data.barcode_base64 = canvas.toDataURL("image/png");
          }

          this.appointmentData = res.data;
          this.$store.dispatch(APPOINTMENT_LOGS, {
            app_id: appId
          });
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    IndexClick(index) {
      if (this.showActionButton === index) {
        this.showActionButton = "";
      } else {
        this.showActionButton = index;
      }
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
      this.appData();
    },
    changeEnrollmentArchive(enroll_id, tcn, onsite) {
      this.$router.push({
        name: "client.changearchive.enrollment",
        params: {
          enroll_id: enroll_id,
          tcn: tcn,
          onsite: onsite
        }
      });
    },
    closeChangeStatusFlag(index) {
      this.selectedAppId = 0;
      this.appoinments[index].change_status_flag = false;
    },
    storeStatusChange(appId, appt_status, index, status) {
      // console.log(status);

      this.$store
        .dispatch(UPDATE_APPOINTMENT_STATUS, {
          app_id: appId,
          appt_status: appt_status
        })
        .then(res => {
          this.$toastr.s(res.msg);
          this.appoinments[index].appt_status = status;
          if (status == "Cancel") {
            this.appoinments[index].cancel = 1;
            // this.appoinments[index].payment_status = "U";
          } else {
            this.appoinments[index].cancel = 0;
            // this.appoinments[index].payment_status = "P";
          }

          this.closeChangeStatusFlag(index);
        })
        .catch(err => {
          this.$toastr.e(err.msg);
        });
    },
    openEnrollmentDetail(enrollmentId, index) {
      this.currentIndex = index;
      this.enrollmentDetailModalShow = true;
      this.selectedAppId = enrollmentId;
      this.loadingData = true;
      var data = {
        enrollment_id: enrollmentId
      };
      // console.log(data);
      this.$store.dispatch(ENROLLMENT_LOGS, {
        enrollment_id: enrollmentId
      });
      this.$store
        .dispatch(FETCH_ENROLLMENT, data)
        .then(res => {
          this.displayLogs = true;
          // console.log(res);
          this.enrollmentData = res.data;
          this.loadingData = false;
          this.hasData = res.hasData;
          this.key += 1;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    assignEnrollmentCompany(enrollmentId, index) {
      this.currentIndex = index;
      this.enrollmentDetailModalShow = true;
      this.selectedAppId = enrollmentId;
      this.loadingData = true;
      var data = {
        enrollment_id: enrollmentId
      };
      // console.log(data);
      this.$store.dispatch(ENROLLMENT_LOGS, {
        enrollment_id: enrollmentId
      });
      this.$store
        .dispatch(FETCH_ENROLLMENT, data)
        .then(res => {
          this.displayLogs = false;
          // console.log(res);
          this.enrollmentData = res.data;
          this.loadingData = false;
          this.hasData = res.hasData;
          this.key += 1;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    edit(enrollmentId, index) {
      var data_ = {
        enrollment_id: enrollmentId
      };
      // this.enrollmentid = parseInt(enrollment_id);

      this.$store
        .dispatch(FETCH_ARCHIVAL_ENROLLMENT, data_)
        .then(res => {
          this.currentIndex = index;
          this.AssignModalShow = true;
          this.archivalData = res.data;
          this.loadingData = false;
          this.hasData = res.hasData;
          this.key += 1;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    updateStatusChangesFlag(appId, index, appt_status) {
      var status = this.appoinments[index].new_status;
      if (status != this.appoinments[index].appt_status) {
        if (status == "Cancel") {
          let message = `You want to cancel the appointment no. ${this.appoinments[index].id}`;
          this.$swal
            .fire({
              title: "Are you sure?",
              text: message,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
              cancelButtonText: "No"
            })
            .then(result => {
              if (result.isConfirmed) {
                this.storeStatusChange(appId, appt_status, index, status);
              } else {
                this.closeChangeStatusFlag(index);
              }
            });
        } else {
          this.storeStatusChange(appId, appt_status, index, status);
        }
      } else {
        this.appoinments[index].appt_status = status;
        this.closeChangeStatusFlag(index);
      }
    },
    changeStatusOpen(appointMentId, index) {
      // console.log(this.appoinments[index]);
      if (this.appoinments[index].cancel == 0) {
        this.selectedAppId = appointMentId;
        this.appoinments[index].change_status_flag = true;
      }
    },
    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      this.appData();
    },
    loadServices() {
      this.service = "all";
      this.archival_period = "all";
      this.$store.dispatch(FETCH_SERVICES, this.serviceOutlet);
      this.$store.dispatch(FETCH_ARCHIVALS, this.serviceOutlet);
    },
    startDownload() {
      this.generateReport = true;
      this.loadingMessage =
        "please wait <br> Your report is being generated...";
    },
    finishDownload() {
      this.generateReport = false;
      this.loadingMessage = "";
    },
    appData() {
      this.appoinments = [];
      this.getLoadingData = true;
      //   console.log(this.archival_period);
      var data_ = {
        search_text: this.search_text,
        service: this.service,
        service_outlet: this.serviceOutlet,
        date_from: this.date_from,
        date_to: this.date_to,
        archival_period: this.archival_period,
        type: this.currentUser.u.is_client ? "c" : "sp",
        page: this.pagination.current_page,
        company: this.company
      };
      var data = {
        service: this.service,
        service_outlet: this.serviceOutlet,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.currentUser.u.is_client ? "c" : "sp",
        status: this.status,
        payment_status: this.payment_status,
        page: this.pagination.current_page,
        search_text: this.search_text,
        dtype: "appointment_list"
      };
      window.localStorage.setItem("appoitmentsearch", JSON.stringify(data));
      this.$store
        .dispatch(FETCH_CLIENT_ARCHIVAL_APPOINTMENT, data_)
        .then(res => {
          this.getLoadingData = false;
          this.appoinments = res.data;
          this.pagination.total_records = res.total_record;
          this.pagination.total_page = res.page_range;
          this.pagination.received_per_page_data = res.data.length;
          this.pagination.per_page_data =
            this.DashType == "appointment_list" ? 15 : 8;

          // console.log(res);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    }
  }
};
</script>
