<style lang="scss" scoped>
#f-detail {
  .close {
    display: none;
  }
  .log {
    font-size: 12px;
    overflow-y: scroll;
    position: absolute;
    height: 77%;
    width: 94%;

    table th {
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1; // any positive value, layer order is global
      background: #fff; // any bg-color to overlap
    }
  }
  .pull-right {
    float: right;
    margin-top: -4%;
  }
  .refund-btn {
    margin-left: 53%;
  }
  .list-unstyled {
    li {
      label {
        font-size: 14px;
      }
      .detail {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
  .sub-header {
    font-size: 16px;
    font-weight: 500;
  }
  .img-outlet-logo {
    width: 90px;
    height: 60px;
  }
  .modal-body {
    padding-top: 0;
  }
  .refund-service {
    display: inline-flex;
    float: right;
  }
  .custom-file-upload {
    padding: 6px 12px;
    cursor: pointer;
    background: #1bc5bd;
    color: white;
    border-radius: 3px;
  }
  .multiselect__content-wrapper {
    width: 150% !important;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <b-modal
    id="f-detail"
    no-close-on-backdrop
    centered
    size="md"
    v-model="showModal"
    :title="'Retrieve for location'"
  >
    <div class="row">
      <div class="col-12" style="padding: 10px">
        <ul class="list-unstyled">
          <li>
            <label for="">Applicant: </label>
            <label for="" class="detail">{{ archivalData.applicant }}</label>
          </li>
          <li>
            <label for="">Enrollment :</label>
            <label for="" class="detail">{{
              archivalData.enrollment_id
            }}</label>
          </li>
          <li>
            <label for="">TCN: </label>
            <label for="" class="detail">{{ archivalData.tcn }}</label>
          </li>
        </ul>
      </div>
      <div class="col-12" style="padding: 10px">
        <label for="">Select Location <span class="text-danger">*</span></label>

        <multiselect
          key="id"
          :options="getBLocations.data ? getBLocations.data : []"
          placeholder="Select Location "
          label="name"
          track-by="id"
          :preselect-first="false"
          v-model="selected"
        ></multiselect>
        <span v-if="errors['client_id']" class="text-danger">{{
          errors["client_id"][0]
        }}</span>
        <!-- <input
          type="text"
          name=""
          class="form-control"
          id=""
          v-model="apikey.client_name"
          v-if="is_edit"
          disabled
        /> -->
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          @click="save()"
          variant="success"
          size="sm"
          class="float-right ml-5"
        >
          Save
        </b-button>

        <b-button
          @click="close()"
          variant="danger"
          size="sm"
          class="float-right"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import {} from "@/core/services/store/mutations.type";
import Multiselect from "vue-multiselect";
import {
  FETCH_B_LOCATIONS,
  ASSIGN_ARCHIVAL_ENROLLMENT,
} from "@/core/services/store/actions.type";
// import { json } from "body-parser";
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    archivalData: {
      type: Object,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      errors: [],
      selected: "",
      key: 0,
      client_id: "",
    };
  },

  components: { Multiselect },
  computed: {
    ...mapGetters(["getBLocations"]),
  },

  mounted() {
    this.getLocationData();

    this.selected = this.archivalData.client;
    document.body.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) {
        this.$emit("closemodal");
      }
    });
  },
  methods: {
    getLocationData() {
      this.$store.dispatch(FETCH_B_LOCATIONS);
    },
    close() {
      this.$emit("closemodal");
    },

    save() {
      if (this.selected) {
        this.$swal({
          title: "Are you sure?",
          text:
            "You want to retrieve this enrolllment for location " +
            this.selected.name,

          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true,
        }).then((willChange) => {
          if (willChange.isConfirmed) {
            var data_ = {
              client_id: this.selected.id,
              enrollment_id: this.archivalData.enrollment_id,
            };

            this.$store
              .dispatch(ASSIGN_ARCHIVAL_ENROLLMENT, data_)
              .then(() => {
                this.$toastr.s("Enrollment scheduled to retrieve");

                this.$emit("closemodal");
              })
              .catch((err) => {
                this.errors = err;
              });
          }
        });
      } else {
        this.$toastr.e("Please select a location");
      }
    },
  },
};
</script>
